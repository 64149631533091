import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import Flex from '../../components/flex';
import CaseStudyNav from "../../components/caseStudyNav";
import CaseStudyOverview from "../../components/caseStudyOverview";
import Hr from "../../components/hr";
import Research from "../../components/research";
import Column from "../../components/column";
import CaseStudySection from "../../components/caseStudySection";

import userImage1 from '../../images/flexjobs-user1.jpg';
import userImage2 from '../../images/flexjobs-user2.jpg';
import userImage3 from '../../images/flexjobs-user3.jpg';
import UserCard from "../../components/userCard";
import Quote from "../../components/quote";
import DesignSection from "../../components/designSection";

const AppleMusic = ({
  uri,
}) => {
  const {
    banner: { childImageSharp: { fluid: banner } },
    taskAnalysis: { childImageSharp: { fluid: taskAnalysis } },
    tableFindings: { childImageSharp: { fluid: tableFindings } },
    findingsImage: { childImageSharp: { fluid: findingsImage } },
    userPersona: { childImageSharp: { fluid: userPersona } },
    wireFramePrototyping: { childImageSharp: { fluid: wireFramePrototyping } },
    design1: { childImageSharp: { fluid: design1 } },
    design2: { childImageSharp: { fluid: design2 } },
    design3: { childImageSharp: { fluid: design3 } },
  } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "apple-music-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      taskAnalysis: file(relativePath: { eq: "casestudy-flexjobs-image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tableFindings: file(relativePath: { eq: "casestudy-flexjobs-image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      findingsImage: file(relativePath: { eq: "casestudy-flexjobs-image3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      userPersona: file(relativePath: { eq: "casestudy-applemusic-image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wireFramePrototyping: file(relativePath: { eq: "casestudy-applemusic-image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design1: file(relativePath: { eq: "casestudy-flexjobs-image5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design2: file(relativePath: { eq: "casestudy-flexjobs-image6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design3: file(relativePath: { eq: "casestudy-flexjobs-image7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Apple Music UX Case Study" />
      <Flex
        flexDirection="column"
        alignItems="center"
      >
        <Img fluid={banner} style={{ width: '100%', }} />
        <Flex
          flexDirection="column"
          alignItems="center"
          width={1}
          px={[20, null, 0]}
        >
          <CaseStudyNav uri={uri} design={false} />
          <CaseStudyOverview
            context="A UX design side project"
            team="Solo, under mentorship"
            role="UX Designer"
            timeline="2 Week sprint"
            deliverables="Research findings, user persona, wireframes, interactive prototypes, high fidelity mockups of proposed changes to user interface design"
            title="APPLE MUSIC"
            type="UX Case Study"
            description="Apple Music is a music and video streaming service developed by Apple Inc. Users select music to stream to their device on-demand, or they can listen to existing, curated playlists. For this study, I focused on three main problems I discovered with Apple Music and sought evidence to support my theories through contextual inquiry, surveying and interviewing users."
          />
          <Hr />
          <Research
            challenges={(
              <>
                <Flex>• Use of metaphors in iconography</Flex>
                <Flex>• Hierarchy of navigation</Flex>
                <Flex>• Discoverability of common tasks</Flex>
              </>
            )}
            solutions={(
              <div>
                To be an advocate for users means to provide clarity — which enables flow. Employing some simple design changes would provide more clear paths for these processes. With a few minor revisions to the user interface, the overall experience could be vastly improved. Through research and contextual inquiry, I was able to confirm my hypotheses about some of the usability issues with Apple Music on iOS.
              </div>
            )}

          />
          <CaseStudySection>
            <h4>Methodology</h4>
            <div>
              As of September, 2018, <strong>Apple Music had approximately 19% market share </strong>according to Midia. As such, it was not difficult to find users. I asked around in my different social circles to find people who use <strong>Apple Music for their main source of music streaming</strong>  and recruited four people <strong>to survey and to conduct usability tests.</strong>
              Through <strong>video chat and in person interviews</strong>, I posed question to users in order to gain feedback on the hierarchical structure of the navigation and to gain insight into how they went about completing tasks. <strong>Using “Thinking Aloud” testing</strong>, I observed how the users <strong>engage with the app in their own environment</strong> and took notes.
            </div>
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="empathize">Empathize</h2>
            <h4>Users</h4>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <Column>
                <Flex fontWeight="bold">Alex is a Passive User</Flex>
                <div>
                  He uses Apple Music passively, mainly listening to <strong>radio and curated content</strong>. He <strong>does not want to dedicate time or effort</strong> into building a personal library.
                </div>
              </Column>
              <Column>
                <Flex fontWeight="bold">Manny is an Aficionado User</Flex>
                <div>
                  He uses Apple Music to <strong>curate his own personal music library</strong>, discover new artists and music by artists he follows, makes and listens to playlists.
                </div>
              </Column>
              <Column last>
                <Flex fontWeight="bold">Ashley & Scott are Hybrid Users</Flex>
                <div>
                  They use Apple Music <strong>for radio</strong>, much like the passive user and they <strong>periodically add to their library</strong> and/or make and listen to playlists.
                </div>
              </Column>
            </Flex>
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={userPersona} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection
            alignItems="center"
          >
            <Quote
            quote="“The (cloud)️ and (heart)️ are a little confusing I don’t know if I’m purchasing a song or borrowing it or saving it or what.”"
              attribute="- Manny"
            />
        </CaseStudySection>
          <CaseStudySection>
            <Img fluid={wireFramePrototyping} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <CaseStudySection
            alignItems="center"
        >
        <Quote
          quote="“I am not sure if the cloud means that songs will be downloaded to my icloud account and take up storage space.”"
          attribute="- Ashley"
        />
        </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="ideate">Ideate</h2>
            <h4>Proposed design changes</h4>
            <p>To bean advocate for users means to provide clarity which enables flow.
              My goal is to make completing common tasks as seamless as possible
              for all user types. Employing some design changes will provide more
              clear paths for these processes.</p>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <Column>
                <Flex fontWeight="bold">Clarifying text</Flex>
                <div>
                  Small <strong>titles can be added, adjacent to the icons</strong> and/or <strong>tooltips</strong> can be displayed when a user taps or long presses on the icons.
                </div>
              </Column>
              <Column>
                <Flex fontWeight="bold">Streamline icons</Flex>
                <div>
                  The heart shape that represents the “For You” section can be replaced
                  with something else, making the heart a <strong>universal visual metaphor</strong>for “like” only.
                </div>
              </Column>
              <Column last>
                <Flex fontWeight="bold">Additional visual status indicators</Flex>
                <div>
                  Additional icons or other visuals can be added to <strong>show the user the status</strong> of a song, artist, curated content set, or album. For example, the cloud icon can change into a checkmark next to the tracks that have already been downloaded to indicate to the user. 
                </div>
              </Column>
            </Flex>
          </CaseStudySection>
            <CaseStudySection>
              <h4>Methodology</h4>
              <div>
                <p>
                Through research and <strong>contextual inquiry,</strong> I was able to <strong>validate my
                assumptions </strong>about some of the usability issues with Apple Music on iOS.
                For the aficionado user, I discovered that the <strong>learning curve is steep</strong>
                when they wish to <strong>deeply engage with the content.</strong> For the passive
                user, the tasks are very basic and the problems affect them less;
                however, they are able to see and acknowledge some issues that do
                exist. For the hybrid user, usability issues affect their ability to
                engage with the content but they <strong>do not wish to invest extra time into
                learning how to.</strong> If the experience was more intuitive, these hybrid users
                may convert to aficionados.
                </p>
                <p>
                  With a few minor revisions to the user interface, the overall experience
could be vastly improved. The application has a lot of potential to
overtake Spotify as the market leader for music streaming services.
                </p>
            </div>
            </CaseStudySection>
          <Hr />

        </Flex>
      </Flex>
    </Layout>
  );
};

export default AppleMusic;
